
import { useDocType } from "@/network/officialDoc"
import {
  inject,
  defineProps,
  defineEmit,
  toRefs,
  ref,
  reactive,
  watch,
  computed,
  toRef,
  onMounted,
  nextTick,
  defineComponent,
} from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number
    },
    setting: {
      type: Object
    }
  },
  emits: ["change"],
  setup(props, { emit: emits }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const { isLoading, result: form, find, save } = useDocType(id);


    const refForm = ref<any>(null);


    const doSave = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          save().then(() => {
            visible.value = false;
            emits("change", form);
          });
        }
      });
    };

    const rules = {
      label: [
        {
          required: true,
          message: "请填写批文类型!",
          trigger: "blur",
        }
      ]
      , sort_Seq: [{
        required: true,
        message: "请填写排序，排序影响列表显示顺序",
        trigger: "blur",
      }, { type: 'number', message: '请填写数字' }]
    };

    onMounted(() => {
      find().then((p) => {
        nextTick(() => {
          refForm.value.clearValidate()
        })
      });
    })
    return {
      refForm,
      isLoading,
      user,
      form,
      rules,
      doSave,
      visible
    }
  }
})


