
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { usePageQueryDocTypes, useDocType } from "@/network/officialDoc"

import Base from "@/views/layout/Base.vue";
import Manage from "./manage.vue";

export default defineComponent({
  components: {
    Base,
    Manage
  },
  setup(props) {
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      keyword: null
    });

    const manageSetting = reactive({
      id: null,
      visible: false,
    });


    const [isLoading, pagerData, query] = usePageQueryDocTypes(filter);
    const id = ref(0);
    const { isLoading: isProcessing, remove } = useDocType(null);

    const edit = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };
    const handleCurrentChange = (e) => {
      filter.pageNum = e;
      query();
    };
    const handleSizeChange = (e) => {
      filter.pageSize = e;
      query();
    }
    onMounted(() => {
      query();
    })

    return {
      filter,
      isLoading, pagerData, query,
      remove,
      edit,
      manageSetting,
      isProcessing,
      handleCurrentChange,
      handleSizeChange
    }
  }
})

