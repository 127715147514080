<template>
  <el-dialog v-model="visible" title="批文类型管理">
    <el-card v-loading="isLoading">
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <el-form-item label="批文类型" prop="label">
            <el-input v-model="form.label" placeholder="批文类型"></el-input>
          </el-form-item>
          <!-- <el-form-item label="排序" prop="sort_Seq">
            <el-input v-model.number="form.sort_Seq" placeholder="排序"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-popconfirm title="确认保存吗！？" @confirm="doSave()" placement="top">
          <template #reference>
            <el-button type="primary" :loading="isLoading" icon="el-icon-check">
              {{ isLoading ? "处理中" : "保 存" }}
            </el-button>
          </template>
        </el-popconfirm>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { useDocType } from "@/network/officialDoc"
import {
  inject,
  defineProps,
  defineEmit,
  toRefs,
  ref,
  reactive,
  watch,
  computed,
  toRef,
  onMounted,
  nextTick,
  defineComponent,
} from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number
    },
    setting: {
      type: Object
    }
  },
  emits: ["change"],
  setup(props, { emit: emits }) {
    const user: any = inject("user");
    const { visible, id } = toRefs(props.setting as any);
    const { isLoading, result: form, find, save } = useDocType(id);


    const refForm = ref<any>(null);


    const doSave = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          save().then(() => {
            visible.value = false;
            emits("change", form);
          });
        }
      });
    };

    const rules = {
      label: [
        {
          required: true,
          message: "请填写批文类型!",
          trigger: "blur",
        }
      ]
      , sort_Seq: [{
        required: true,
        message: "请填写排序，排序影响列表显示顺序",
        trigger: "blur",
      }, { type: 'number', message: '请填写数字' }]
    };

    onMounted(() => {
      find().then((p) => {
        nextTick(() => {
          refForm.value.clearValidate()
        })
      });
    })
    return {
      refForm,
      isLoading,
      user,
      form,
      rules,
      doSave,
      visible
    }
  }
})


</script>

<style scoped></style>