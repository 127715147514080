<template>
  <Base title="批文类型管理" titleIcon="el-icon-s-claim">
  <div>
    <div class="search-row">
      <el-space>
        <el-button type="primary" icon="el-icon-plus" @click="edit(null)">添加批文类型</el-button>
        <el-input v-model="filter.keyword" placeholder="查询关键字"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="query({ pageNum: 1 })">查询</el-button>
      </el-space>
    </div>
  </div>
  <Manage :setting="manageSetting" @change="query({ pageNum: 1 })" v-if="manageSetting.visible"></Manage>
  <el-table :data="pagerData.list" v-loading="isLoading">
    <el-table-column label="批文类型" prop="label"></el-table-column>
    <el-table-column label="状态" prop="usedCount">
      <template #default="{ row: item }">
        {{ item.usedCount > 0 ? `已使用(${item.usedCount})` : '未使用' }}
      </template>
    </el-table-column>
    <el-table-column label="操作" width="260">
      <template #default="{ row: item }">
        <el-button type="primary" @click="edit(item.id)" size="mini" icon="el-icon-edit">修改</el-button>
        <el-popconfirm title="确认删除吗！？" @confirm="remove(item.id).then(() => query())" placement="top"
          v-if="item.usedCount < 1">
          <template #reference>
            <el-button type="danger" icon="el-icon-delete" size="mini" :loading="isProcessing">
              删除
            </el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination style="padding-left: 5px" @current-change="handleCurrentChange" @size-change="handleSizeChange"
    v-model:page-size="filter.pageSize" :total="pagerData.count" layout="sizes,total, prev, pager, next"
    v-model:currentPage="filter.pageNum"></el-pagination>
  </Base>
</template>
<script lang="ts">
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { usePageQueryDocTypes, useDocType } from "@/network/officialDoc"

import Base from "@/views/layout/Base.vue";
import Manage from "./manage.vue";

export default defineComponent({
  components: {
    Base,
    Manage
  },
  setup(props) {
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      keyword: null
    });

    const manageSetting = reactive({
      id: null,
      visible: false,
    });


    const [isLoading, pagerData, query] = usePageQueryDocTypes(filter);
    const id = ref(0);
    const { isLoading: isProcessing, remove } = useDocType(null);

    const edit = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };
    const handleCurrentChange = (e) => {
      filter.pageNum = e;
      query();
    };
    const handleSizeChange = (e) => {
      filter.pageSize = e;
      query();
    }
    onMounted(() => {
      query();
    })

    return {
      filter,
      isLoading, pagerData, query,
      remove,
      edit,
      manageSetting,
      isProcessing,
      handleCurrentChange,
      handleSizeChange
    }
  }
})

</script>
<style lang="scss" scoped></style>